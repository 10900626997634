import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { auth, onAuthStateChanged } from "./utils/firebase";
import { login, logout } from "./redux/AuthSlice";

const ProtectedRoute = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (userAuth) => {
      if (userAuth) {
        dispatch(
          login({
            email: userAuth.email,
            uid: userAuth.uid,
            displayName: userAuth.displayName,
            phoneNumber: userAuth.phoneNumber
          })
        );
        setLoading(false);
      } else {
        dispatch(logout());
        setLoading(false);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [dispatch]);

  const { user, isAuthenticated } = useSelector(
    (state) => state.login
  );

  if (loading) {
    return (
      <div className="bg-white grid h-screen w-screen justify-center items-center align-center">
        <div className="border-gray-300 h-16 w-16 animate-spin rounded-full border-8 border-t-blue-600" />
      </div>
    );
  }

  if (!user || !isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;

import React, { useState, useRef, useEffect } from 'react';
import { options } from '../../constant';
import { useSelector, useDispatch } from 'react-redux';
import { addSelectedModStudy } from '../../redux/Report-builder/SelectedModStudy'
import { removeReport } from '../../redux/Report-builder/ReportContents'
import { resetMessage } from '../../redux/Report-builder/ChatMessages'
import { resetSelectedPathology } from '../../redux/Report-builder/SelectedPathologies'


export default function CustomDropdown() {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();
    const modality = useSelector(state => state.reportBuilder.jsonData);
    const [selectedValue, setSelectedValue] = useState(() => {
        return sessionStorage.getItem('selectedDropdownValue') || 'Select an option';
    });


    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [dropdownRef]);

    const handleSelect = (option) => {
        setSelectedValue(option);
        sessionStorage.setItem('selectedDropdownValue', option);
        dispatch(addSelectedModStudy(option));
        dispatch(resetMessage());
        dispatch(removeReport([]));
        dispatch(resetSelectedPathology());
        setIsOpen(false);
    };

    useEffect(() => {
        const savedValue = sessionStorage.getItem('selectedDropdownValue');
        if (savedValue) {
            setSelectedValue(savedValue);
            dispatch(addSelectedModStudy(savedValue));
        } else {
            const selectedValue = modality.mod_study || 'Select an option';
            setSelectedValue(selectedValue);
            sessionStorage.setItem('selectedDropdownValue', selectedValue);
            dispatch(addSelectedModStudy(selectedValue));
        }
    }, [modality, dispatch]);

    


    return (
        <div className="relative w-[40%] m-2" ref={dropdownRef}>
            <div
                className="py-2 rounded-lg cursor-pointer text-sm font-bold flex items-center"
                onClick={() => setIsOpen(!isOpen)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={`h-6 w-6 mr-2 transform ${isOpen ? 'rotate-180' : ''}`}>
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
                {selectedValue.length > 20 ? `${selectedValue.substring(0, 20)}...` : selectedValue}
            </div>
            {isOpen && (
                <ul className="absolute z-10 w-full bg-white border border-gray-300 mt-1 rounded shadow-lg">
                    {options.map((option, index) => (
                        <li
                            key={index}
                            className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            onClick={() => handleSelect(option)}
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
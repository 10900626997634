import Select from 'react-select';
import { organ_list } from '../constant';
import PatientData from '../components/PatientData';

export const getValue = (value, fallback) => {
  return value ? value : fallback ? fallback : "-";
};

export function findVariableProperty(reportContents, pathology, variableName, property) {

  const reportContent = reportContents.find(content => content.pathology === pathology);

  if (reportContent) {
    const question = reportContent.question.find(input => input.key === variableName.replace('_', ''));

    if (question && question.hasOwnProperty(property)) {
      return question[property];
    }
  }
  return null;
}

export function streamPathologyContent(data, lastPath) {
  let observation = '<h3 id="observation-header"><b>Observation:-</b></h3>';
  let impression = '\n\n<h3 id="impression-header"><b>Impression:-</b></h3>';

  data.map((each) => {
    if (each['pathology'] !== 'normal') {
      let formattedObservation = each['observation'];
      formattedObservation = formattedObservation.replace(/\b_\w+/g, `<span id="obs-${each['pathology']}" style="background-color: #f5f7a6;">$&</span>`);
      observation += `<p id="obs-${each['pathology']}">${formattedObservation}</p>`;

      if (each['impression'] !== 'NA') {
        impression += `<p id="imp-${each['pathology']}">` + each['impression'] + '</p>';
      }
    }
  });

  if (lastPath && lastPath['pathology']) {
    let formattedObservation = lastPath['observation'];
    formattedObservation = formattedObservation.replace(/\b_\w+/g, `<span id="obs-${lastPath['pathology']}" style="background-color: #f5f7a6;">$&</span>`);
    observation += `<p id="obs-${lastPath['pathology']}">${formattedObservation}</p>`;

    if (lastPath['impression'] !== 'NA' && lastPath['impression'].length > 3) {
      impression += `<p id="imp-${lastPath['pathology']}"></p>`;
    }
  }
  return observation + impression;
}


export function streamFullReportContent(data, selectedModStudyValue) {
  const sessionStorageItem = sessionStorage.getItem('patientData');

  let patientDataValues;
  let patientData;
  try {
    patientData = JSON.parse(sessionStorageItem);
    patientDataValues = patientData.sex.toLowerCase();
  } catch (error) {
    console.error("Error parsing sessionStorage item:", error);
    patientData = {};
  }

  let observation = '<h3 id="observation-header"><b>Observation:-</b></h3>';
  let impression = '<h3 id="impression-header"><b>Impression:-</b></h3>';

  let filteredOrganList = organ_list[selectedModStudyValue].filter(organ => {
    if (patientDataValues === 'female') {
      return organ !== 'prostate' && organ !== 'ab prostate';
    } else if (patientDataValues === 'male') {
      return organ !== 'uterus' && organ !== 'ab uterus';
    }
    return true; 
  });

  filteredOrganList.forEach((organ) => {
    let temp_observation = '';
    let temp_impression = '';

    data.filter(i => i['organ'] === organ && i['pathology'] !== 'normal').forEach((obs) => {
      if (obs['observation'] !== 'NA') temp_observation += `<p id="obs-${obs['pathology']}">` + obs['observation'] + '</p>';
      if (obs['impression'] !== 'NA') temp_impression += `<p id="imp-${obs['pathology']}">` + obs['impression'] + '</p>';
    });

    data.filter(i => i['organ'] === organ && i['pathology'] === 'normal').forEach((obs) => {
      temp_observation += `<p id="obs-normal">` + obs['observation'] + '</p>';
    });

    if (!['ab manual', 'ab other', 'other'].includes(organ)) {
      if (temp_observation) {
        organ = organ.replace('ab ', '');
        observation += `\n<p id="obs-tittle"><strong>` + organ.charAt(0).toUpperCase() + organ.slice(1) + ':</strong></p>';
      }
    } else { observation += '\n'; }
    observation += temp_observation;
    impression += temp_impression;
  });

  return observation + impression;
}


export function getVaildVariables(question, selectedOptions) {
  const variables = [];

  for (const input of question) {
    if (input.type !== 'heading' && input.type !== 'link') {
      variables.push(input.key);
      if (input.followup === 'true') {
        const selectedOption = selectedOptions?.[input.key];
        if (selectedOption) {
          const followupInput = input.to_followup[selectedOption];
          if (followupInput) {
            if (followupInput.type === 'single choice') {
              variables.push(...getVaildVariables([followupInput], selectedOptions));
            }
          }
        }
      }
    }
  }
  return variables;
}

export function getAdditionalText(question, selectedOptions) {
  let additionalText = '';
  for (const input of question) {
    if (input.followup === 'true') {
      const selectedOption = selectedOptions[input.key];
      if (selectedOption) {
        let followupInput;
        if (input.type === 'input box') {
          followupInput = input.to_followup[input.key];
        }
        else { followupInput = input.to_followup[selectedOption] };
        if (followupInput) {
          if (followupInput.additional_text) {
            additionalText += followupInput.additional_text + ' ';
          } else if (followupInput.type === 'single choice' || followupInput.type === 'input box') {
            additionalText += getAdditionalText([followupInput], selectedOptions);
          }
        }
      }
    }
  }
  return additionalText.trim();
}


export const renderInput = (input, options, pathology, handleInputChange, measurementSize, updateMeasurementSize, pathologyFormData, followup = true) => {
  if (!input) return;

  const dropDownOptions = options[input.key] ? options[input.key].map((optionValue) => ({ value: optionValue, label: optionValue })) : [];

  switch (input.type) {
    case 'heading':

      return (<h2 key={input.key}>Do answer the following question on <b>{input.value}</b>
        {followup && input.followup === 'true' && pathologyFormData?.[pathology]?.[input.key] && input.to_followup[pathologyFormData[pathology][input.key]] !== 'NA'
          && renderInput(input.to_followup[input.key], options, pathology, handleInputChange, measurementSize, updateMeasurementSize, pathologyFormData)}
      </h2>);
    case 'size':
      return (
        <div key={input.key}>
          <label>{input.value}</label>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '200px' }}>
            <input
              type="number"
              name={`${input.key}_CC`}
              className='border-none bg-transparent px-1'
              placeholder="---"
              required
              style={{ width: '50px' }}
              onChange={(e) => updateMeasurementSize(e, input.key, 'CC', pathology)}
            />
            <input
              type="number"
              name={`${input.key}_AP`}
              className='border-none bg-transparent px-1'
              placeholder="---"
              required
              style={{ width: '50px' }}
              onChange={(e) => updateMeasurementSize(e, input.key, 'AP', pathology)}
            />
            <input
              type="number"
              name={`${input.key}_TR`}
              className='border-none bg-transparent px-1'
              placeholder="---"
              required
              style={{ width: '50px' }}
              onChange={(e) => updateMeasurementSize(e, input.key, 'TR', pathology)}
            />
            <input
              type="hidden"
              name={input.key}
              value={`${measurementSize.CC} x ${measurementSize.AP} x ${measurementSize.TR}`}
            />
          </div>
        </div>
      )
    case 'multiple choice':
      return (
        <div key={input.key}>
          <label>{input.value}</label>
          <Select
            isMulti
            name={input.key}
            options={dropDownOptions}
            classNamePrefix="select"
            maxMenuHeight={200}
            placeholder="---"
            menuPortalTarget={document.body}
            onChange={(event) => { handleInputChange(input.key, event.map(option => option.value).join(', '), pathology) }}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 9999 }),
              control: (base) => ({
                ...base,
                background: "transparent",
                borderColor: "none",
                color: 'black'
              }),
            }}
          />
        </div>
      )
    case 'single choice':
      return (
        <div key={input.key}>
          <label>{input.value}</label>
          <Select
            name={input.key}
            options={dropDownOptions}
            placeholder={pathologyFormData?.[pathology]?.[input.key] ? pathologyFormData[pathology][input.key] : '---'}
            classNamePrefix="select"
            maxMenuHeight={200}
            menuPortalTarget={document.body}
            onChange={(event) => { handleInputChange(input.key, event.value, pathology) }}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 9999 }),
              control: (base) => ({
                ...base,
                background: "transparent",
                borderColor: "none",
                color: 'black'
              }),
            }}
          />
          {followup && input.followup === 'true' && pathologyFormData?.[pathology]?.[input.key] && input.to_followup[pathologyFormData[pathology][input.key]] !== 'NA' && input.to_followup[pathologyFormData[pathology][input.key]]
            && renderInput(input.to_followup[pathologyFormData[pathology][input.key]], options, pathology, handleInputChange, measurementSize, updateMeasurementSize, pathologyFormData)}
        </div>
      );
    case 'input box':
      return (
        <div key={input.key}>
          <label>{input.value}</label>
          <input
            type="text"
            onChange={(e) => handleInputChange(input.key, e.target.value, pathology)}
            className="border-none bg-transparent px-1 "
            style={{ backgroundColor: 'transparent', borderBottom: '1px solid', width: '15%' }}
          />
          <p> <br></br> </p>
        </div>
      );
    case 'link':
      return (
        <p>
          <br></br>
          {input.value}
          <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
            href={input.url} target="_blank" rel="noopener noreferrer">
            link
          </a>
        </p>
      );
    default:
      return null;
  }
};


export function copyHTMLToClipboard(htmlContent) {
  const tempElem = document.createElement('div');
  tempElem.innerHTML = htmlContent;
  tempElem.style.position = 'absolute';
  tempElem.style.left = '-9999px';
  document.body.appendChild(tempElem);
  const selection = window.getSelection();
  const range = document.createRange();
  range.selectNodeContents(tempElem);
  selection.removeAllRanges();
  selection.addRange(range);
  try {
    document.execCommand('copy');
  } catch (err) {
  }
  document.body.removeChild(tempElem);
}


export function getOptionFromReportContents(reportContents, pathology, label) {
  const report = reportContents?.find(item => item.pathology === pathology);
  if (report) {
    return report.variable[label.replace('_', '')]
  }
}

export function updateObservationVariables(reportContents, pathologyFormData) {
  return reportContents.map(content => {
    if (content.pathology !== 'normal' && content.pathology !== 'manual') {
      let updatedObservation = content.observation_init;

      for (const [key, value] of Object.entries(pathologyFormData[content.pathology] || {})) {
        const variableName = `_${key}`;
        const variableRegex = new RegExp(variableName, 'g');

        if (typeof value === 'string') {
          updatedObservation = updatedObservation.replace(variableRegex, value);
        }
      }

      return {
        ...content,
        observation: updatedObservation,
      };
    }
    return content;
  });
}



export function getAdditionalQuestions(reportContents, pathologyFormData, pathology) {
  const additionalQuestions = [];

  function processQuestion(questionObj, pathology, variable) {
    const content = reportContents.find((c) => c.pathology === pathology);
    if (content && !content.observation_init.includes(`_${questionObj.key}`)) {
      if ((!pathologyFormData[pathology] || !pathologyFormData[pathology][questionObj.key]) && !questionObj.hasOwnProperty('additional_text')) {
        const existingPathologyIndex = additionalQuestions.findIndex(
          (q) => q.pathology === pathology
        );
        if (existingPathologyIndex !== -1) {
          additionalQuestions[existingPathologyIndex].question.push(questionObj);
        } else {
          additionalQuestions.push({
            question: [{ type: 'heading', key: '', value: content.pathology }, questionObj],
            pathology,
            variable,
          });
        }
      }
    }

    if (questionObj.followup === 'true' && pathologyFormData[pathology] && pathologyFormData[pathology][questionObj.key]) {
      const selectedOption = pathologyFormData[pathology][questionObj.key];
      const followupQuestion = questionObj.to_followup[selectedOption];
      if (followupQuestion && followupQuestion !== 'NA') {
        processQuestion(followupQuestion, pathology, variable);
      }
    }
  }

  const content = reportContents.find((c) => c.pathology === pathology);
  if (content) {
    content.question.forEach((questionObj) => {
      processQuestion(questionObj, pathology, content.variable);
    });
  }

  const filteredAdditionalQuestions = additionalQuestions.filter((item) => {
    return item.question.length > 1 && !item.question.includes('additional text');
  });

  return filteredAdditionalQuestions;
}


export function checkForEligibleAdditionalQuestion(reportContents, pathologyFormData) {
  const eligiblePathologies = [];

  reportContents.forEach((content) => {
    if (content.pathology !== 'normal' && content.pathology !== 'manual') {
      const underscoreVariables = content.observation.match(/_\w+/g) || [];

      if (underscoreVariables.length) {
        const allVariablesPresent = underscoreVariables.every((variable) => {
          const extractedVariable = variable.slice(1);
          return pathologyFormData[content.pathology] && pathologyFormData[content.pathology][extractedVariable];
        });


        if (allVariablesPresent) {
          eligiblePathologies.push(content.pathology);
        }
      }
    }
  });

  return getAdditionalQuestions?.(reportContents, pathologyFormData, eligiblePathologies?.[0])[0]
}
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { auth } from "../utils/firebase";
import { logout } from "../redux/AuthSlice";
import { requestStudiesList } from "../redux/OsteroCheckSlice";
import { MdDelete } from "react-icons/md";
import { IoClose } from 'react-icons/io5';
import { MdAutoDelete } from "react-icons/md";
import { deleteAllData } from "../redux/OsteroCheckSlice"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoIosLogOut } from "react-icons/io";


const Navbar = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comments, setComments] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, success } = useSelector(state => state.OsteroCheck.deleteAllData);
  const hasData = useSelector(state => state.OsteroCheck.patientList.data.data);
  const location = useLocation();



  const handleProfile = () => {
    setMenuVisible(!isMenuVisible);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };



  const profile = useSelector((state) => state.login.user);

  const fetchDataOnClick = () => {
    dispatch(requestStudiesList());
  };

  useEffect(() => {
    fetchDataOnClick();
  }, []);


  const capitalizeEmailUsername = (email) => {
    if (!email) return '';
    const [username] = email.split('@');
    return `Dr. ${username.charAt(0).toUpperCase() + username.slice(1)}`;
  };

  const handleDeleteAllData = () => {
    if (!hasData) {
      toast.info("No data to delete.");
      return;
    }

    dispatch(deleteAllData())
      .then(() => {
        toast.success("All data deleted successfully!");
        setTimeout(() => {
          dispatch({ type: 'requestStudiesList/resetDeleteAllDataStatus' });
        }, 3000);
      })
      .catch((err) => {
        toast.error(err.message || "An error occurred while deleting data");
      });
    toggleModal();
  };




  return (
    <div className="flex justify-between items-center pr-4">
      <img
        onClick={() => navigate("/list")}
        className="object-contain cursor-pointer h-16 sm:w-[2.5rem]"
        alt=""
        src="/img/5clogo.png"
      />
      <div className="flex items-center gap-7">
        {profile?.email === "naveen@5cnetwork.com" && location.pathname === "/list" && (
          <button
            className="bg-"
            onClick={toggleModal}
            disabled={!hasData || hasData.length === 0}
          >
            <MdDelete size={26} className={`text ${!hasData || hasData.length === 0 ? 'opacity-50' : ''}`} />
          </button>
        )}


        <div className="flex">
          <button
            type="button"
            className="text-gray-700 block w-full py-2 text-left text-sm"
            role="menuitem"
            id="menu-item-3"
            onClick={() => {
              dispatch(logout());
              auth.signOut();
            }}>
            <IoIosLogOut size={32}/>
          </button>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={toggleModal}
        onSubmit={handleDeleteAllData}
        isSubmitting={false}
        error=""
        comments={comments}
        setComments={setComments}
        hasData={hasData}
      />
    </div>
  );
};
const Modal = ({ isOpen, onClose, onSubmit, isSubmitting, error, comments, setComments, hasData }) => {
  const [animationState, setAnimationState] = useState('closed');


  useEffect(() => {
    if (isOpen) {
      setAnimationState('opening');
      setTimeout(() => setAnimationState('open'), 300);
    } else if (animationState !== 'closed') {
      setAnimationState('closing');
      setTimeout(() => setAnimationState('closed'), 300);
    }
  }, [isOpen]);

  const handleClose = () => {
    setAnimationState('closing');
    setTimeout(() => {
      onClose();
    }, 300);
  };

  const handleSubmit = () => {
    onSubmit(); // Call the passed onSubmit function
    handleClose(); // Close the modal
  };



  if (animationState === 'closed') return null;



  return (
    <div className={`fixed inset-0 bg-black flex justify-center items-center z-50 transition-opacity duration-300 ${animationState === 'opening' || animationState === 'open' ? 'bg-opacity-50' : 'bg-opacity-0'
      }`}>
      {animationState !== 'closed' && (
        <div className={`max-w-sm mx-auto bg-white rounded-lg shadow-md p-8 relative transition-all duration-300 ${animationState === 'opening' ? 'modal-opening' :
          animationState === 'closing' ? 'modal-closing' :
            animationState === 'open' ? 'scale-100 opacity-100' : 'scale-95 opacity-0'
          }`}>
          <button onClick={handleClose} className="absolute top-3 right-3 text-gray-500 hover:text-gray-700">
            <IoClose size={24} />
          </button>
          <div className="flex justify-center mb-4">
            <div className="bg-red-100 rounded-full p-3">
              <MdAutoDelete className="text-red-500" size={23} />
            </div>
          </div>

          <h2 className="text-2xl font-semibold text-center mb-4">Delete data?</h2>

          <p className="text-center mb-6 text-[13px]">
            {hasData
              ? "You're going to delete the data. Are you sure?"
              : "There is no data to delete."}
          </p>

          <div className="flex justify-center space-x-4">
            <button
              className="px-4 py-2 text-base bg-gray-200 text-gray-800 rounded-badge hover:bg-gray-300 transition-colors"
              onClick={handleClose}
            >
              {hasData ? "No, Keep It." : "Close"}
            </button>
            {hasData && (
              <button
                className="px-4 py-2 text-base bg-[#4359A5] text-white rounded-badge hover:bg-[#5170bf] transition-colors"
                onClick={handleSubmit}
              >
                Yes, Delete!
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default Navbar;
import { configureStore } from "@reduxjs/toolkit";
import LoginReducer from "./AuthSlice";
import OsteroCheckSlice from "./OsteroCheckSlice";
import { useDispatch } from "react-redux";
import userReducerReport from './Report-builder/ReportContents';
import userChatMessages from './Report-builder/ChatMessages';
import userSelectedPathologies from './Report-builder/SelectedPathologies';
import userSelectedModStudy from './Report-builder/SelectedModStudy';
import pathologyFormData from './Report-builder/PathologyFormData';
import ReportBuilder from "./Report-builder/ReportBuilder";
import augnito from './Report-builder/Augnito'

export const store = configureStore({
  reducer: {
    login: LoginReducer,
    OsteroCheck: OsteroCheckSlice,
    reportContents : userReducerReport,
    chatMessages : userChatMessages,
    selectedPathologies : userSelectedPathologies,
    selectedModStudy : userSelectedModStudy,
    pathologyFormData: pathologyFormData,
    augnito : augnito,
    reportBuilder:ReportBuilder
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false, 
  }),
});

export default store;

export const useAppDispatch = () => useDispatch();

export const RootState = store.getState();
export const AppDispatch = store.dispatch;
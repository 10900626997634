import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const baseUrl = "https://backend.bionic-demo.cubebase.ai/"

export const requestStudiesList = createAsyncThunk(
  "requestStudiesList",
  async (_, thunkAPI) => {
    try {
      const response = await fetch(
        `${baseUrl}/data`,
        {
          method: "GET"
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue("Failed to fetch studies list");
    }
  }
);

export const deleteAllData = createAsyncThunk(
  "deleteAllData",
  async (_, thunkAPI) => {
    try {
      const response = await fetch(
        `${baseUrl}/delete-all-data`,
        {
          method: "DELETE"
        }
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue("Failed to delete all data");
    }
  }
);

export const dashboardStudiesList = createAsyncThunk(
  "dashboardStudiesList",
  async (_, thunkAPI) => {
    try {
      const response = await fetch(
        `${baseUrl}/image-stats`,
        {
          method: "GET"
        }
      );
      const data = await response.json();
      // console.log(data.data);
      return data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue("Failed to fetch studies list");
    }
  }
);

export const updateDashboardStudy = createAsyncThunk(
  "updateDashboardStudy",
  async (data, thunkAPI) => {
    try {
      const response = await fetch(
        `${baseUrl}/update-study`,
        {
          method: "PUT",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        }
      );
      const result = await response.json();
      return result;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue("Failed to update dashboard study");
    }
  }
);

export const requestReport = createAsyncThunk(
  "requestReport",
  async (resData, thunkAPI) => {
    try {
      console.log(resData);
      const response = await fetch(
        `${baseUrl}/get-info/${resData}`,
        {
          method: "GET"
        }
      );
      const data = await response.json();
      // console.log(data);
      return data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue("Failed to fetch studies list");
    }
  }
);

export const downloadReport = createAsyncThunk(
  "downloadReport",
  async (resData, thunkAPI) => {
    try {
      // console.log(resData);
      const response = await fetch(
        `${baseUrl}/report/get-report?order_id=${resData}`,
        {
          method: "GET"
        }
      );
      const data = await response.json();
      // console.log(data);
      return data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue("Failed to fetch studies list");
    }
  }
);

export const requestAddPatient = createAsyncThunk(
  "requestAddPatient",
  async (data, thunkAPI) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      let filename = "";
      if (data.formData instanceof FormData) {
        filename = data.formData.getAll("filename")[0];
      } else if (typeof data.formData === 'object' && data.formData !== null) {
        filename = data.formData.filename || "";
      }

      var raw = JSON.stringify({
        "patient_name": data.patient_data.name,
        "patient_age": data.patient_data.age.toString(),
        "patient_sex": data.patient_data.sex,
        "patient_history": data.patient_data.history,
        "filename": filename,
        "mod_study": data.patient_data.mod_study,
        "html_string": data.htmlString,
        "pathologies": data.pathologyData,
        "emergency":data.patient_data.emergency
      });
      // console.log("Sending data to API:", raw);

      const response = await fetch(
        `${baseUrl}/insert-chestr`,
        {
          headers: myHeaders,
          method: "POST",
          body: raw
        }
      );
      
      if (!response.ok) {
        const errorData = await response.text();
        console.error("API response not ok:", response.status, errorData);
        return thunkAPI.rejectWithValue(`API error: ${response.status} ${errorData}`);
      }

      const resData = await response.json();
      
      if (resData.details) {
        return thunkAPI.rejectWithValue(`Validation error: ${resData.details}`);
      }

      return { ...resData, json: resData.json };
    } catch (error) {
      console.error("Error in requestAddPatient:", error);
      return thunkAPI.rejectWithValue(error.message || "Network error occurred");
    }
  }
);
const initialState = {
  patientList: {
    data: { data: [] },
    loading: false,
    error: null
  },
  dashboardList: {
    data: { data: [] },
    loading: false,
    error: null
  },
  patientData: {
    data: {
      name: "",
      age: 0,
      sex: "",
      history: "",
      study_link: "",
      report_link: "",
      mod_study: "",
      json: { url: "", observations: [], impression: [], pathologies: [] },
      pathologyData: [], 
      htmlString: "",
      action: { case: false, feedback: false, emergency: true }
    },
    loading: false,
    error: null
  },
  report: { loading: false, error: null },
  downloadReport: { loading: false, error: null },
  deleteAllData: {
    loading: false,
    error: null,
    success: false
  }
};

export const OsteroCheckSlice = createSlice({
  name: "requestStudiesList",
  initialState,
  reducers: {
    addPatient: (state, action) => {
      state.patientData.data = { ...state.patientData.data, ...action.payload };
    },
    resetDeleteAllDataStatus: (state) => {
      state.deleteAllData.success = false;
      state.deleteAllData.error = null;
    },
    clearPatientData: (state) => {
      state.patientData.data = {
        name: "",
        age: 0,
        sex: "",
        history: "",
        study_link: "",
        report_link: "",
        mod_study: "",
        json: { url: "", observations: [], impression: [], pathologies: [] },
        pathologyData: [], 
        htmlString: "",
        action: { case: false, feedback: false, emergency: true }
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestStudiesList.pending, (state) => {
        state.patientList.loading = true;
        state.patientList.error = null;
      })
      .addCase(requestStudiesList.fulfilled, (state, action) => {
        state.patientList.loading = false;
        state.patientList.data = action.payload;
      })
      .addCase(requestStudiesList.rejected, (state, action) => {
        state.patientList.loading = false;
        state.patientList.error =
          action.error.message || "Something went wrong";
      })
      .addCase(dashboardStudiesList.pending, (state) => {
        state.dashboardList.loading = true;
        state.dashboardList.error = null;
      })
      .addCase(dashboardStudiesList.fulfilled, (state, action) => {
        state.dashboardList.loading = false;
        state.dashboardList.data = action.payload;
      })
      .addCase(dashboardStudiesList.rejected, (state, action) => {
        state.dashboardList.loading = false;
        state.dashboardList.error =
          action.error.message || "Something went wrong";
      })
      .addCase(updateDashboardStudy.pending, (state) => {
        state.dashboardList.loading = true;
        state.dashboardList.error = null;
      })
      .addCase(updateDashboardStudy.fulfilled, (state, action) => {
        state.dashboardList.loading = false;
      })
      .addCase(updateDashboardStudy.rejected, (state, action) => {
        state.dashboardList.loading = false;
        state.dashboardList.error =
          action.error.message || "Failed to update dashboard study";
      })
      .addCase(requestReport.pending, (state) => {
        state.patientData.loading = true;
        state.patientData.error = null;
      })
      .addCase(requestReport.fulfilled, (state, action) => {
        state.patientData.loading = false;
        // state.patientData.data.history=action.payload.data[0]?.history
        state.patientData.data.name = action.payload.data[0]?.patient_name;
        state.patientData.data.sex = action.payload.data[0]?.patient_sex;
        state.patientData.data.mod_study = action.payload.data[0]?.mod_study;
        state.patientData.data.history = action.payload.data[0]?.history;
        state.patientData.data.age = action.payload.data[0]?.patient_age;
        state.patientData.data.report_link = action.payload.data[0]?.report_link
        state.patientData.data.order_id = action.payload.data[0]?.order_id;
        state.patientData.data.json = action.payload.data[0]?.json;
        state.patientData.data.action.case = action.payload.data[0]?.action.case;
        state.patientData.data.action.feedback = action.payload.data[0]?.action.feedback;
        state.patientData.data.action.emergency = action.payload.data[0]?.action.emergency;
      })
      .addCase(requestReport.rejected, (state, action) => {
        state.patientData.loading = false;
        state.patientData.error =
          action.error.message || "Something went wrong";
      })
      .addCase(requestAddPatient.pending, (state) => {
        state.report.loading = true;
        state.patientData.error = null;
      })
      .addCase(requestAddPatient.fulfilled, (state, action) => {
        state.report.loading = false;
        state.patientData.data.json = action.payload.json;
        state.patientData.data.study_link = action.payload.study_link;
        state.patientData.data.report_link = action.payload.report_link;
        // console.log("json ", action.payload.json)
      })
      .addCase(requestAddPatient.rejected, (state, action) => {
        state.report.loading = false;
        state.patientData.error =
          action.error.message || "Something went wrong";
      })
      .addCase(deleteAllData.pending, (state) => {
        state.deleteAllData.loading = true;
        state.deleteAllData.error = null;
        state.deleteAllData.success = false;
      })
      .addCase(deleteAllData.fulfilled, (state, action) => {
        state.deleteAllData.loading = false;
        state.deleteAllData.success = true;
        // You might want to clear other parts of the state here as well
        state.patientList.data = { data: [] };
        state.dashboardList.data = { data: [] };
      })
      .addCase(deleteAllData.rejected, (state, action) => {
        state.deleteAllData.loading = false;
        state.deleteAllData.error = action.error.message || "Failed to delete all data";
        state.deleteAllData.success = false;
      })
      .addCase(downloadReport.pending, (state) => {
        state.report.loading = true;
        state.patientData.error = null;
      })
      .addCase(downloadReport.fulfilled, (state, action) => {
        state.report.loading = false;
        state.patientData.data.report_link = action.payload.report_link;
      })
      .addCase(downloadReport.rejected, (state, action) => {
        state.report.loading = false;
        state.patientData.error =
          action.error.message || "Something went wrong";
      });
  }
});

export const { addPatient,resetDeleteAllDataStatus,clearPatientData } = OsteroCheckSlice.actions;
export default OsteroCheckSlice.reducer;
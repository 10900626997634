import React, { useState } from 'react';
import { X, Maximize2, Loader2 } from 'lucide-react';
import { useSelector } from 'react-redux';
import { MdRestore } from "react-icons/md";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dicom = () => {
    const [link, setLink] = useState('');
    const [resetValue, setResetValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRestoring, setIsRestoring] = useState(false); // Added state for restore animation
    const patientData = useSelector((state) => state.OsteroCheck.patientData);

    const requestOptions = {
        method: 'GET',
        redirect: 'follow',
    };

    const number = patientData.data.json.url.split('/').pop().split('_')[0];

    const handleClick = async () => {
        let studyId = 3094928;

        if (studyId) {
            setIsOpen(true);
            setIsLoading(true);

            try {
                const studyInfoResponse = await fetch(`https://api.5cnetwork.com/study/info/${number}`);
                const studyInfoData = await studyInfoResponse.json();
                setResetValue(studyInfoData.study_iuid);

                if (studyInfoData && studyInfoData.study_iuid) {
                    const apiUrl = `https://api.5cnetwork.com/dicom/v2/sharable-image-link?study_iuid=${studyInfoData.study_iuid}&server=dcm4cheeArcLight`;
                    const linkResponse = await fetch(apiUrl);
                    const linkResult = await linkResponse.text();
                    setLink(linkResult);
                } else {
                    console.error('No study_iuid found in the response');
                }
            } catch (error) {
                console.error('Error fetching study info or image link:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleResetButtonClick = () => {
        setIsRestoring(true); // Start animation
        const apiUrl = `https://api.5cnetwork.com/dicom/restore?study_iuid=${resetValue}`;
        fetch(apiUrl, requestOptions)
            .then((response) => response.text())
            .then(() => {
                toast.success("Restore completed!");
            })
            .catch((error) => {
                console.error(error);
                toast.error("Restore failed.");
            })
            .finally(() => {
                setIsRestoring(false); // Stop animation
            });
    };

    return (
        <>
            <ToastContainer />
            <button
                onClick={handleClick}
                className="fixed bottom-0 left-16 flex items-center gap-16 px-6 py-3 text-white bg-[#4459a5] rounded-tl-xl rounded-tr-xl hover:bg-[#1a2542] transition-colors duration-200"
            >
                <span className="font-semibold">Dicom viewer</span>
                <Maximize2 className="w-5 h-5" />
            </button>

            <div
                className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
                    }`}
                onClick={() => setIsOpen(false)}
            />

            <div
                className={`fixed bottom-0 left-0 bg-white rounded-tr-xl shadow-lg transition-all duration-300 ease-in-out transform origin-bottom-left ${isOpen ? 'w-full h-[90%] opacity-100 scale-100 z-[100]' : 'w-0 h-0 opacity-0 scale-0'
                    }`}
                style={{
                    maxWidth: '100%',
                    maxHeight: '95vh',
                }}
            >
                <div className="flex items-center justify-between p-4 border-b bg-[#4459a5] rounded-tr-lg">
                    <h2 className="text-lg font-semibold text-white">DICOM Viewer</h2>
                    <div className='flex items-center gap-2 '>
                        <div className="w-6 h-6 text-white hover:cursor-pointer" onClick={handleResetButtonClick}>
                            {isRestoring ? (
                                <div className="loader"></div> // Custom loader
                            ) : (
                                <MdRestore className="w-6 h-6" />
                            )}
                        </div>
                        <button
                            onClick={() => {
                                setIsOpen(false);
                                setIsLoading(false);
                                setLink('');
                            }}
                            className="p-1 transition-colors duration-200 rounded-full"
                        >

                            <X className="w-6 h-6 text-white" />
                        </button>

                    </div>
                </div>

                <div className="h-[calc(100%-4rem)]">
                    {isLoading ? (
                        <div className="flex items-center justify-center w-full h-full bg-gray-100">
                            <div className="text-center">
                                <Loader2 className="w-12 h-12 mx-auto mb-4 text-[#4459a5] animate-spin" />
                                <p className="text-gray-600">Loading DICOM viewer...</p>
                            </div>
                        </div>
                    ) : link ? (
                        <iframe
                            src={link}
                            title="Dicom-viewer"
                            className="w-full h-full border-0"
                            allowFullScreen
                        />
                    ) : (
                        <div className="flex items-center justify-center w-full h-full bg-gray-100">
                            <p className="text-gray-600">No DICOM viewer content available</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Dicom;

import React,{useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from "../redux/store";
import { IoLayersSharp, IoChatbubbleEllipsesSharp } from 'react-icons/io5';
import { setPathologyFromColIndex, setEmergencyCase, setSurgicalCase ,setfiltermodalities,setfilterStatus,setfilterPathology,setfilterCase} from '../redux/Report-builder/ReportBuilder';
import { HiDocumentReport } from "react-icons/hi";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const appDispatch=useAppDispatch();
  const isSelected = (path) => location.pathname === path;

  useEffect(() => {
    if (location.pathname === '/overview') {
      appDispatch(setPathologyFromColIndex(false));
      appDispatch(setfiltermodalities([]));
      appDispatch(setfilterStatus([]));
      appDispatch(setfilterPathology([]));
      appDispatch(setfilterCase([]));
    }
}, [location, appDispatch]);


  return (
    <div className='h-full 2xl:w-[4%] bg-white w-[4.3%] left-0 border border-r-solid overflow-y-auto'>
      <div 
        className={`h-[7%] w-full flex justify-center items-center cursor-pointer 
          ${isSelected('/list') ? 'border-l-4 border-[#4359A5] bg-[#e3eaf6]' : 'hover:bg-[#e3eaf6]'}`}
        onClick={() => navigate('/list')}
      >
        <IoLayersSharp size={20} className={isSelected('/list') ? 'text-[#252b46]' : 'text-slate-400'} />
      </div>
      <div 
        className={`h-[7%] w-full flex justify-center items-center cursor-pointer 
          ${isSelected('/overview') ? 'border-l-4 border-[#4359A5] bg-[#e3eaf6]' : 'hover:bg-[#e3eaf6]'}`}
          onClick={() => {
            appDispatch(setPathologyFromColIndex(false));
            appDispatch(setfiltermodalities([]));
            appDispatch(setfilterStatus([]));
            appDispatch(setfilterPathology([]));
            appDispatch(setfilterCase([]));
            navigate('/overview');
          }}
      >
        <HiDocumentReport  size={20} className={isSelected('/overview') ? 'text-[#252b46]' : 'text-slate-400'} />
      </div>
      <div 
        className={`h-[7%] w-full flex justify-center items-center cursor-pointer 
          ${isSelected('/chat') ? 'border-l-4 border-[#4359A5] bg-[#e3eaf6]' : 'hover:bg-[#e3eaf6]'}`}
        onClick={() => navigate('/chat')}
      >
        <IoChatbubbleEllipsesSharp size={20} className={isSelected('/chat') ? 'text-[#252b46]' : 'text-slate-400'} />
      </div>
     
    </div>
  );
};

export default Sidebar;

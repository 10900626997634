import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { requestAddPatient,requestStudiesList } from "../redux/OsteroCheckSlice";
import { useAppDispatch } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { LuUpload } from "react-icons/lu";
import { IoCloseCircle } from "react-icons/io5";
import { setFormData, setJsonData } from "../redux/Report-builder/ReportBuilder";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { IoCreateOutline } from "react-icons/io5";
import { AiOutlineRobot } from "react-icons/ai";
import { setReasonData } from '../redux/Report-builder/ReportBuilder';

const App = ({ onSubmit, fileInputRefs, patientFormValues, isFormValid, debug, preSelectedFiles, previewImageUrl }) => {
  const navigate = useNavigate();
  const patientListvalues = useSelector((state) => state.OsteroCheck.patientList);
  const appDispatch = useAppDispatch();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentPreviewUrl, setCurrentPreviewUrl] = useState(previewImageUrl);
  const fileInputRef = useRef(null);
  const ZIP_ICON_SIZE = 48;


  useEffect(() => {
    if (preSelectedFiles) {
      setSelectedFiles(preSelectedFiles);
      setCurrentPreviewUrl(previewImageUrl);
    }
  }, [preSelectedFiles, previewImageUrl]);

  const handleFileInputChange = (event) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files).map((file) => ({
        id: file.name,
        name: file.name,
        size: file.size,
        type: file.type,
        url: file.type.startsWith('image/') ? URL.createObjectURL(file) : './img/zip.png'
      }));

      setSelectedFiles(newFiles);
      setCurrentPreviewUrl(newFiles[0]?.url || null);
    }
  };

  const handleFileDelete = () => {
    setSelectedFiles([]);
    setCurrentPreviewUrl(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };



  const handleUpload = async () => {
    onSubmit();
    const formData = new FormData();

    await Promise.all(
      selectedFiles.map(async (file) => {
        try {
          const response = await fetch(file.url);
          const blob = await response.blob();

          formData.append("filename", file.name);
        } catch (error) {
          console.error("Error fetching image data:", error);
        }
      })
    );
    Object.entries(patientFormValues).forEach(([key, value]) => {
      formData.append(key, value.toString());
    });

    onSubmit();

    await appDispatch(setFormData(formData));
    appDispatch(setJsonData(patientFormValues))
    appDispatch(requestStudiesList());
    console.log("patientlistvalues",patientListvalues)
    if (["CT thorax", "CT Abdomen Plain and Contrast", "CT PNS", "MRI Knee", "CT Throax", "CT KUB", "CT Temporal Bone"].includes(patientFormValues.mod_study)) {
      navigate("/report-builder");
    } else if (selectedFiles.some(file => file.name === "3619969.zip" || file.name === "3239514.zip")) {
      await appDispatch(
        requestAddPatient({ patient_data: patientFormValues, formData: formData, pathologyData: [], htmlString: '' })
      );
      navigate({
        pathname: "/process",
        search: "?type=In progress"
      });
    }
    else {
      await appDispatch(
        requestAddPatient({ patient_data: patientFormValues, formData: formData, pathologyData: [], htmlString: '' })
      );
      navigate("/reports");
    }

    // if (!report.loading) {
    //   navigate("/reports");
    // }
  };

  const handleCancel = () => {
    setSelectedFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer.files) {
      const newFiles = Array.from(event.dataTransfer.files).map(
        (file) => ({
          id: file.name,
          name: file.name,
          size: file.size,
          type: file.type,
          url: URL.createObjectURL(file)
        })
      );

      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
  };
  const handlePreviewImageDelete = () => {
    setSelectedFiles([]);
  };

  const getModifiedFileName = (fileName) => {
    const nameParts = fileName.split('.');
    if (nameParts.length > 1) {
      nameParts.pop();
    }
    return nameParts.join('.');
  };


  const isButtonEnabled = () => {
    const requiredFields = ['name', 'sex', 'age', 'mod_study', 'history'];
    const areRequiredFieldsFilled = requiredFields.every(field => !!patientFormValues[field]);
    return areRequiredFieldsFilled && selectedFiles.length > 0;
  };


  const getButtonLabelAndIcon = () => {
    const modStudy = patientFormValues.mod_study;
    const studiesWithCreateReport = ["CT Abdomen Plain and Contrast", "CT KUB", "CT PNS", "CT Temporal Bone", "CT Throax", "MRI Knee"];

    if (studiesWithCreateReport.includes(modStudy)) {
      return { label: "Create Report", icon: <IoCreateOutline size={20} /> };
    }
    return { label: "Generate Report", icon: <img src='./img/ai.png' alt="AI icon" /> };
  };

  const { label, icon } = getButtonLabelAndIcon();


  return (
    <div className="w-[50%] flex flex-col justify-start ">
      <article className="border-dashed border-[#4359A5] border-[2px] rounded-xl mt-6 ml-6 flex-grow bg-[#F5F5F5] flex flex-col h-full">
        {selectedFiles.length === 0 ? (
          <div
            className="flex flex-col justify-center items-center gap-y-2 h-full"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDragEnter={handleDragEnter}
          >
            <div className="bg-white rounded-full p-4">
              <LuUpload size={55} />
            </div>
            <p className="text-gray-900 flex flex-wrap text-[14px]">
              Drag and Drop your Study or&nbsp;
              <span className="text-[#4359A5] font-bold underline cursor-pointer" onClick={() => fileInputRef.current?.click()}>Browse</span>
            </p>
            <input
              type="file"
              multiple
              ref={fileInputRef}
              onChange={handleFileInputChange}
              className="hidden"
            />
          </div>
        ) : (
          <div className="flex-grow overflow-auto p-1">
            <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 justify-items-center h-full">
              {selectedFiles.map((file) => (
                <li key={file.id} className="w-full sm:w-[21rem]">
                  <article className="group w-full h-full rounded-md focus:outline-none focus:shadow-outline bg-[#F6F8FF] cursor-pointer relative shadow-sm hover:bg-black hover:bg-opacity-40">
                    {file.url === './img/zip.png' ? (
                      <div className="w-full h-full flex flex-col gap-2 items-center justify-center rounded-md hover:bg-white hover:bg-opacity-20 p-4">
                        <img
                          src='./img/zip.png'
                          alt="zip icon"
                          className="object-contain"
                          style={{ width: `${ZIP_ICON_SIZE}px`, height: `${ZIP_ICON_SIZE}px` }}
                        />
                        <span className="text-xl flex items-center justify-center w-full">
                          anonymized scan
                          <button
                            className="delete focus:outline-none p-1 rounded-md"
                            onClick={handleFileDelete}>
                            <IoCloseCircle size={24} className="text-[#df1818]" />
                          </button>
                        </span>
                      </div>
                    ) : (
                      <div className="flex flex-col items-center gap-10">
                        <div className="w-full h-[350px] flex items-center justify-center overflow-hidden">
                          <img
                            src={currentPreviewUrl || file.url}
                            alt="upload preview"
                            className="img-preview object-cover w-full h-full rounded-md"
                            style={{ objectFit: 'contain' }}
                          />
                        </div>
                        <div className="bottom-0 left-0 right-0 bg-opacity-50 text-white p-2 flex justify-between w-full items-center">
                          <span className="truncate text-black font-normal text-opacity-80">anonymized scan</span>
                          <button
                            className="text-black text-opacity-80 rounded-full p-1 ml-2"
                            onClick={handleFileDelete}
                          >
                            <MdOutlineDeleteOutline size={20} />
                          </button>
                        </div>
                      </div>

                    )}
                  </article>
                </li>
              ))}
            </ul>
          </div>
        )}
      </article>
      <div className=" m-2 ml-6 mr-0">
        {/* <button
          className={`w-full h-full inline-flex text-[14px] justify-center p-3 items-center gap-2 text-white font-semibold rounded-lg ${isButtonEnabled() ? 'bg-[#4359A5]' : 'bg-[#e3eaf6]'}`}
          onClick={handleUpload}
          disabled={!isButtonEnabled()}
        >
          <img src='./img/ai.png' alt="AI icon" />
          Generate Report
        </button> */}

        <button
          className={`w-full h-full inline-flex text-[14px] justify-center p-3 items-center gap-2 text-white font-semibold rounded-lg ${isButtonEnabled() ? 'bg-[#4359A5]' : 'bg-[#e3eaf6]'}`}
          disabled={!isButtonEnabled()}
          onClick={handleUpload}
        >
          {icon} {label}
        </button>
      </div>
    </div>
  );
};



export default App;
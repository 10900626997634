import React from "react";

const ProgressButton = (props) => {
  const { isDone, color } = props;

  if (isDone) {
    return (
      <button
        className={`flex items-center justify-center p-1 rounded-full ${color} text-white`}
      >
        <svg
          style={{ color: "white" }}
          className="w-4 h-4"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="40"
          zoomAndPan="magnify"
          viewBox="0 0 30 30.000001"
          height="40"
          preserveAspectRatio="xMidYMid meet"
          version="1.0"
        >
          <defs>
            <clipPath id="id1">
              <path
                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                clipRule="nonzero"
                fill="white"
              ></path>
            </clipPath>
          </defs>
          <g clipPath="url(#id1)">
            <path
              fill="white"
              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
              fillOpacity="1"
              fillRule="nonzero"
            ></path>
          </g>
        </svg>
      </button>
    );
  }
  return (
    <div className="flex justify-end">
      <div className="bg-white border-4 border-logo-blue  h-6 w-6 rounded-full "></div>
    </div>
  );
};

const ProgressBar = (props) => {
  const { patient_info, upload_scan, report } = props;
  return (
    <div className="grid mt-4">
      <div className="flex justify-center items-center px-14 sm:px-0">
        <ProgressButton isDone={patient_info.isDone} color={"bg-logo-blue"} />

        <div className="h-1 w-full bg-neutral-200">
          {patient_info.loading > 0 && (
            <div
              className="h-1 bg-logo-blue"
              style={{ width: patient_info.loading + "%" }}
            ></div>
          )}
        </div>
        <ProgressButton isDone={upload_scan.isDone} color={"bg-logo-blue"} />
        <div className="h-1 w-full bg-neutral-200">
          {upload_scan.loading > 0 && (
            <div
              className="h-1 bg-logo-blue"
              style={{ width: upload_scan.loading + "%" }}
            ></div>
          )}
        </div>
        <ProgressButton
          isDone={report.isDone}
          color={report.color ? "bg-green-700" : "bg-logo-blue"}
        />
      </div>
      <div className="flex justify-between items-center pt-2 px-8  text-sm text-gray-500 sm:px-0">
        <p className="font-bold ">Patient Info</p>
        <p className="font-bold text-sm">Upload Scan</p>
        <p className="font-bold text-sm">Report</p>
      </div>
    </div>
  );
};

export default ProgressBar;

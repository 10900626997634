import React, { useState, useCallback, useMemo,useEffect } from 'react';
import { Augnito, AugnitoConfig, AugnitoAPIServer, ActionRecipe } from 'augnitosdk';
import { whisperStopListening,whisperStartListening,augnitoStartListening,augnitoStopListening,setHanldeConnectionoff} from '../../redux/Report-builder/Augnito'
import styled from 'styled-components';
import { useSelector,useDispatch } from 'react-redux'
import { MicFill, MicMuteFill } from '@styled-icons/bootstrap';
import { debounce } from 'lodash';

const augnitoConfigSetup = () => {
    const augnitoConfig = {
      server: AugnitoAPIServer.INDIA,
      accountCode: process.env.REACT_APP_AUGNITO_ACCOUNT_CODE,
      accessKey: process.env.REACT_APP_AUGNITO_ACCESS_KEY,
      userTag: 1,
      sourceApp: '5C-Network',
      lmId: process.env.REACT_APP_AUGNITO_LMID,
    }
    const augnito = new Augnito(augnitoConfig)
    return augnito
  }
  
  
  
  
  
  const AugnitoSDK = (props) => {
    const { sendMessageStream } = props
    const [partialText, setPartialText] = useState('')
    // const [augnitoConnect, setAugnitoConnect] = useState(false)
    const augnitoConnect=useSelector((state)=>state.augnito.isWhisper)
    const dispatch=useDispatch();
    const whisperData = useSelector((state) => state.augnito.whisperData);
    const augnitoData=useSelector((state)=>state.augnito.valueFromAugntio)
    const augnitoStateConnection = useSelector((state) => state.augnito.isListening);
    // const userData = useSelector((state) => state.user.user);
    const isConnection=useSelector((state)=>state.augnito.isConnection)
    // const isEnabled=useSelector((state)=>state?.user?.isEnabled)
  
  
    const augnito = useMemo(() => augnitoConfigSetup(), [])
  
    augnito.onPartialResult = (text) => {
      setPartialText(text)
    }
  
    augnito.onIdleMic = () => {
      augnito.toggleListening()
    }
  
  
    augnito.onFinalResult = useCallback((finalText) => {
      const handled = true 
      sendMessageStream(finalText?.receivedText)
      return handled
    }, [])
  
    // const fetchDataFromFirestore = async (userData) => {
    //   try {
    //     const docRef = doc(firestore, 'userPermissions', 'ohTjajLJUdtI9O2YWgdy');
    //     const docSnap = await getDoc(docRef);
    
    //     if (docSnap.exists()) {
    //       console.log('firestore data', docSnap.data());
    //       const realstoredata = docSnap.data().report.map(item => ({ isEnabled: item.isEnabled, email: item.email }));
    //       console.log("db data", realstoredata);
    //       console.log("typeofdata", typeof(realstoredata));
    
    //       const matchedItem = realstoredata.find(item => item.email === userData.email);
    
    //       if (matchedItem) {
    //         console.log(`Match found for email ${userData.email}. isEnabled: ${matchedItem.isEnabled}`);
    //         // Only dispatch if isEnabled is still null
    //         if (isEnabled === null) {
    //           dispatch(setEnabled(matchedItem.isEnabled));
    //         }
    //         return matchedItem.isEnabled;
    //       } else {
    //         console.log(`No match found for email ${userData.email}`);
    //         return null;
    //       }
    //     } else {
    //       console.log('No such document!');
    //       return null;
    //     }
    //   } catch (error) {
    //     console.error('Error fetching document:', error);
    //     return null;
    //   }
    // };
    
  
  
    // useEffect(() => {
    //   if (isEnabled === null) {
    //     fetchDataFromFirestore(userData);
    //   }
    // }, [isEnabled, userData]);
    
  
    const handleConnectionOff = () => {
      // augnito.toggleListening()
      augnito.toggleListening()
      dispatch(whisperStopListening())
      dispatch(augnitoStopListening())
      
    }
  
    const handleConnectionOn = () => {
      augnito.toggleListening()
      dispatch(whisperStartListening())
      dispatch(augnitoStartListening())
    }
  
  
  
  
  useEffect(()=>{
    const trimmedText = whisperData.trim().toLowerCase();
    const trimmedTextaungito=augnitoData.trim().toLowerCase();
    console.log("trimmedTextaungito",trimmedTextaungito)
   
      if(!augnitoConnect){
      augnito.toggleListening()
      }
    else if(trimmedTextaungito==='generate report'){
      augnito.toggleListening()
    }
  
  },[whisperData,augnitoConnect])
  
  useEffect(()=>{
    if(augnitoStateConnection){
      augnito.toggleListening()
    }
  },[augnitoStateConnection])
  
  
  
    
  
    return (
      <AugnitoContainer>
        <ToolbarAugnitoContainer>
          {augnitoConnect ? (
            <MicMuteIcon
              height='32'
              width='32'
              onClick={handleConnectionOff}
            />
          ) : (
            <MicIcon
              height='32'
              width='32'
              onClick={handleConnectionOn}
            />
          )}
        </ToolbarAugnitoContainer>
      </AugnitoContainer>
    )
  }
  
  
  
  export default AugnitoSDK
  
  const AugnitoContainer = styled.div`
    display: flex;
    flex-direction: column;
  `
  const MicIcon = styled(MicFill)`
    cursor: pointer;
    border: 1px solid black;
    padding: 3px;
    border-radius: 50%;
  `
  
  const MicMuteIcon = styled(MicMuteFill)`
    cursor: pointer;
    border: 1px solid black;
    padding: 3px;
    border-radius: 50%;
  `
  
  const AugnitoInput = styled.input`
    border-radius: 0.3rem;
    border: 1px solid grey;
  `
  
  const ToolbarAugnitoContainer = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 5px;
  `

import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { dashboardStudiesList } from "../redux/OsteroCheckSlice";
import { useAppDispatch } from "../redux/store";
import Navbar from '../components/Navbar'
import { setfiltermodalities, setfilterStatus, setfilterPathology, setfilterCase } from '../redux/Report-builder/ReportBuilder';
import Sidebar from '../components/Sidebar'
import { useNavigate } from 'react-router-dom';
import { FaPlusCircle } from "react-icons/fa";
import { BsFillHeartbreakFill } from "react-icons/bs";

const TableHead = ({ header }) => {
    return (
        <div className="p-2 font-semibold text-center sm:p-1 sm:text-[10px] sm:w-full">
            {header}
        </div>
    );
};

const Table = ({ headers, data, setSelectedModalities }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleCellClick = (rowIndex, colIndex) => {
        if (colIndex === 0) {
            return;
        }

        const rowName = headers[colIndex].toLowerCase();
        console.log("indexValues", { rowIndex, colIndex });
        let selectedModalities = [];
        if (rowIndex === 0 && colIndex === 1) {
            navigate('/list');
        }
        else if (rowIndex === 1 && colIndex === 2) {
            dispatch(setfiltermodalities(['ChestR', 'SXR', 'KXR']));
            dispatch(setfilterStatus(['AI Autonomous', 'AI Assisted']));
            navigate('/list');
            return;
        }
        else if (rowIndex === 1 && colIndex === 3) {
            dispatch(setfiltermodalities(['CT brain', 'CT Abdomen Plain and Contrast', 'CT PNS', 'CT Throax', 'CT KUB', 'CT Temporal Bone']));
            dispatch(setfilterStatus(['AI Autonomous', 'AI Assisted']));
            navigate('/list');
            return;
        } else if (rowIndex === 1 && colIndex === 4) {
            dispatch(setfiltermodalities(['MRI spine', 'MRI Knee']));
            dispatch(setfilterStatus(['AI Autonomous', 'AI Assisted']));
            navigate('/list');
            return;
        }
        else if (rowIndex === 2 && colIndex === 2) {
            dispatch(setfiltermodalities(['ChestR', 'SXR', 'KXR']));
            dispatch(setfilterStatus(['In progress']));
            navigate('/list');
            return;
        }
        else if (rowIndex === 2 && colIndex === 3) {
            dispatch(setfiltermodalities(['CT brain', 'CT Abdomen Plain and Contrast', 'CT PNS', 'CT Throax', 'CT KUB', 'CT Temporal Bone']));
            dispatch(setfilterStatus(['In progress']));
            navigate('/list');
            return;
        }
        else if (rowIndex === 2 && colIndex === 4) {
            dispatch(setfiltermodalities(['MRI spine', 'MRI Knee']));
            dispatch(setfilterStatus(['In progress']));
            navigate('/list');
            return;
        }
        else if (rowIndex === 3 && colIndex === 1) {
            dispatch(setfilterPathology(['Abnormal']))
            navigate('/list');
            return;
        }
        else if (rowIndex === 3 && colIndex === 2) {
            dispatch(setfiltermodalities(['ChestR', 'SXR', 'KXR']));
            dispatch(setfilterPathology(['Abnormal']))
            navigate('/list');
            return;
        } else if (rowIndex === 3 && colIndex === 3) {
            dispatch(setfiltermodalities(['CT brain', 'CT Abdomen Plain and Contrast', 'CT PNS', 'CT Throax', 'CT KUB', 'CT Temporal Bone']));
            dispatch(setfilterPathology(['Abnormal']))
            navigate('/list');
            return;
        } else if (rowIndex === 3 && colIndex === 4) {
            dispatch(setfiltermodalities(['MRI spine', 'MRI Knee']));
            dispatch(setfilterPathology(['Abnormal']))
            navigate('/list');
            return;
        }
        else if (rowIndex === 4 && colIndex === 1) {
            dispatch(setfilterPathology(['normal']))
            navigate('/list');
            return;
        }
        else if (rowIndex === 4 && colIndex === 2) {
            dispatch(setfiltermodalities(['ChestR', 'SXR', 'KXR']));
            dispatch(setfilterPathology(['Normal']))
            navigate('/list');
            return;
        } else if (rowIndex === 4 && colIndex === 3) {
            dispatch(setfiltermodalities(['CT brain', 'CT Abdomen Plain and Contrast', 'CT PNS', 'CT Throax', 'CT KUB', 'CT Temporal Bone']));
            dispatch(setfilterPathology(['Normal']))
            navigate('/list');
            return;
        } else if (rowIndex === 4 && colIndex === 4) {
            dispatch(setfiltermodalities(['MRI spine', 'MRI Knee']));
            dispatch(setfilterPathology(['normal']))
            navigate('/list');
            return;
        }
        else if (rowIndex === 5 && colIndex === 1) {
            dispatch(setfilterCase(['Emergency']))
            navigate('/list');
            return;
        }
        else if (rowIndex === 5 && colIndex === 2) {
            dispatch(setfiltermodalities(['ChestR', 'SXR', 'KXR']));
            dispatch(setfilterCase(['Emergency']))
            navigate('/list');
            return;
        } else if (rowIndex === 5 && colIndex === 3) {
            dispatch(setfiltermodalities(['CT brain', 'CT Abdomen Plain and Contrast', 'CT PNS', 'CT Throax', 'CT KUB', 'CT Temporal Bone']));
            dispatch(setfilterCase(['Emergency']))
            navigate('/list');
            return;
        } else if (rowIndex === 5 && colIndex === 4) {
            dispatch(setfiltermodalities(['MRI spine', 'MRI Knee']));
            dispatch(setfilterCase(['Emergency']))
            navigate('/list');
            return;
        } else if (rowIndex === 6 && colIndex === 1) {
            dispatch(setfilterCase(['surgical']))
            navigate('/list');
            return;
        }
        else if (rowIndex === 6 && colIndex === 2) {
            dispatch(setfiltermodalities(['ChestR', 'SXR', 'KXR']));
            dispatch(setfilterCase(['surgical']))
            navigate('/list');
            return;
        } else if (rowIndex === 6 && colIndex === 3) {
            dispatch(setfiltermodalities(['CT brain', 'CT Abdomen Plain and Contrast', 'CT PNS', 'CT Throax', 'CT KUB', 'CT Temporal Bone']));
            dispatch(setfilterCase(['surgical']))
            navigate('/list');
            return;
        } else if (rowIndex === 6 && colIndex === 4) {
            dispatch(setfiltermodalities(['MRI spine', 'MRI Knee']));
            dispatch(setfilterCase(['surgical']))
            navigate('/list');
            return;
        }




        if (rowName === 'xray') {
            dispatch(setfiltermodalities(['ChestR', 'SXR', 'KXR']));
            // selectedModalities = ['ChestR', 'SXR', 'KXR'];
        } else if (rowName === 'ct') {
            dispatch(setfiltermodalities(['CT brain', 'CT Abdomen Plain and Contrast', 'CT PNS', 'CT Throax', 'CT KUB', 'CT Temporal Bone']));
            // modalityTypes = ['CT brain', 'CT Abdomen Plain and Contrast', 'CT PNS', 'CT Throax', 'CT KUB', 'CT Temporal Bone'];
        } else if (rowName === 'mri') {
            dispatch(setfiltermodalities(['MRI spine', 'MRI Knee']));
            // modalityTypes = ['MRI spine', 'MRI Knee'];
        }

        // setSelectedModalities(modalityTypes);
        navigate('/list');
    };

    const AnimatedNumber = ({ value }) => {
        const [displayValue, setDisplayValue] = useState(0);

        useEffect(() => {
            let start = 0;
            const end = parseInt(value);
            const duration = 1000;
            const increment = end / (duration / 16);

            const timer = setInterval(() => {
                start += increment;
                setDisplayValue(Math.floor(start));

                if (start >= end) {
                    clearInterval(timer);
                    setDisplayValue(end);
                }
            }, 16);

            return () => clearInterval(timer);
        }, [value]);

        return <span>{displayValue}</span>;
    };

    return (
        <div className="rounded-2xl overflow-hidden text-sm">
            <div className="bg-[#4359A5] sm:px-2 p-2 text-[#ffffff]">
                <div className="flex">
                    {headers.map((header, index) => (
                        <div key={index} className="flex-1 flex justify-center items-center font-bold uppercase">
                            <TableHead header={header} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="overflow-y-auto">
                {data.map((row, rowIndex) => (
                    <div
                        key={rowIndex}
                        className={`flex mb-3 ${rowIndex === data.length - 1 ? 'rounded-b-2xl overflow-hidden' : ''
                            }`}
                    >
                        {headers.map((header, colIndex) => {
                            const isEmergency = row.category.toLowerCase() === 'emergency';
                            // console.log(`Row ${rowIndex}, Column ${header}:`, { category: row.category, isEmergency });

                            return (
                                <div
                                    key={colIndex}
                                    className={`flex-1 2xl:p-8 p-6 text-center bg-white cursor-pointer ${rowIndex === data.length - 1 && colIndex === 0 ? 'rounded-bl-2xl' :
                                        rowIndex === data.length - 1 && colIndex === headers.length - 1 ? 'rounded-br-2xl' : ''
                                        }`}
                                    onClick={() => handleCellClick(rowIndex, colIndex)}
                                >
                                    <div className="flex items-center justify-center">
                                        {header.toLowerCase() === 'category' && (
                                            <div className='flex gap-2 items-center'>
                                                <span>{row.category}</span>
                                                {isEmergency && (
                                                    <FaPlusCircle className="text-[#f27e12]" size={20} />
                                                )}
                                                {row.category.toLowerCase() === 'surgical' && (
                                                    <BsFillHeartbreakFill className="text-[#c51212] heartbeat" size={20} />
                                                )}
                                            </div>
                                        )}
                                        {header.toLowerCase() !== 'category' && (
                                            <span className='text-[#6e8dbf] hover:text-[#4359A5] transition-colors font-normal'>
                                                {row[header.toLowerCase()] === 0 ? '0' : (
                                                    <AnimatedNumber value={row[header.toLowerCase()] || row.category} />
                                                )}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
};

function Overview() {
    const appDispatch = useAppDispatch();
    const [selectedModalities, setSelectedModalities] = useState([]);

    useEffect(() => {
        appDispatch(dashboardStudiesList());
    }, [appDispatch]);

    const { data, loading, error } = useSelector(
        (state) => state.OsteroCheck.dashboardList
    );

    if (loading) {
        return <div className='flex items-center justify-center h-screen w-full'>
            <iframe src="https://lottie.host/embed/1329de50-00a6-4ecf-8436-23a4366023ef/9c9C0i38kl.json" className='w-1/3 h-1/4' ></iframe>
        </div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!data || !data.table || !data.table.rows || data.table.rows.length === 0) {
        return <div>No data available</div>;
    }


    const headers = data.table.columns.map(column => column.charAt(0).toUpperCase() + column.slice(1));

    // console.log("Headers:", headers);
    // console.log("Data:", data.table.rows);

    return (
        <div className="flex flex-col h-screen w-screen bg-[#f5f6fa]">
            <div className="border-b border-solid bg-white">
                <Navbar />
            </div>
            <div className="flex flex-1 overflow-hidden">
                <Sidebar />
                <main className="flex-1 overflow-y-auto fade-in">
                    <div className="p-10">
                        <Table
                            headers={headers}
                            data={data.table.rows}
                            setSelectedModalities={setSelectedModalities}
                        />
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Overview
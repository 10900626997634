import Augnito from './Augnito';
import React, { useState, useRef,useEffect } from 'react';
import { setCursorPosition, setQuill, setTextArea, setSizeInput, setAugnitoData,whisperStartListening ,augnitoStopListening} from '../../redux/Report-builder/Augnito';
import { useSelector, useDispatch } from 'react-redux';
import CustomDropdown from './OptionDropDown';
import { resetSelectedPathology } from '../../redux/Report-builder/SelectedPathologies';
import { resetContent } from '../../redux/Report-builder/ReportContents';

// Dictate component handles the dictation functionality and UI
function Dictate() {
    const dispatch = useDispatch();
    const cursorPositionRef = useRef(null);
    const augnitoValue = useSelector(state => state.augnito.valueFromAugntio);
    const whisperData=useSelector(state=>state.augnito.valueFromAugntio)
    const whisperState = useSelector((state) => state.augnito.isWhisper)



    // Extracting necessary state using useSelector
    const {
        reportContents,
        selectedModStudyValue,
        selectedPathologies,
        cursorPosition,
        textAreaDictate
    } = useSelector(state => ({
        reportContents: state.reportContents.reportContents,
        selectedModStudyValue: state.selectedModStudy.selectedModStudyValue,
        selectedPathologies: state.selectedPathologies.selectedPathologies,
        cursorPosition: state.augnito.cursorPosition,
        textAreaDictate: state.augnito.textAreaDictate,
    }));
    // console.log('Redux state:', state);
    // console.log("values in texaareadictate",textAreaDictate)
    

    cursorPositionRef.current = cursorPosition;
    const [isNonEditable, setIsNonEditable] = useState(false);
    const [nonEditableData, setNonEditableData] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [allFindings, setAllFindings] = useState([]);

    // Function to handle the streaming of text from the microphone
 const onSendMessageStream = (streamText) => {
    // console.log("augnito text",streamText)
    if (streamText.trim().toLowerCase() === 'generate report') {
        return;
    }
        if (cursorPositionRef.current === 'textArea') {
            dispatch(setTextArea({ action: 'add', 'text': streamText }));
        } else if (cursorPositionRef.current === 'quill') {
            dispatch(setQuill(streamText));
        } else if (cursorPositionRef.current === 'sizeInput') {
            dispatch(setSizeInput({ 'action': 'reset', 'text': streamText }));
        }
    };
    useEffect(()=>{
        onSendMessageStream(augnitoValue)
        const trimmedText = whisperData.trim().toLowerCase();
        if(trimmedText==='generate report' && textAreaDictate.length > 5){
                fetchReportContent(textAreaDictate);
        }
    },[augnitoValue])
    


    // Async function to fetch report content based on the input text
    const fetchReportContent = async (inputText) => {
        const newReportContents = [...reportContents];
        const newSelectedPathologies = [...selectedPathologies];
        setIsLoading(true);
        const response = await fetch(`${process.env.REACT_APP_BIONIC_BACKEND_API}/api/chat-input?chat=${inputText.trim()}&mod_study=${selectedModStudyValue}`, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_BIONIC_BACKEND_API_TOKEN}`
            }
        });

        setIsLoading(false);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        const findings = data.map(item => item.finding);
        const existingData = [...allFindings]; 
        const mergedFindings = [...existingData, ...findings];
        setAllFindings(mergedFindings);
        dispatch(setAugnitoData(mergedFindings));
        // dispatch(setHanldeConnectionOn())
        data?.forEach(iter => {
            if (!newSelectedPathologies.includes(iter['finding'])) {
                const impression = iter['impression'] === '-' ? '' : iter['impression'];
                let isLast = true;

                newReportContents.push({
                    pathology: iter['finding'],
                    observation_init: iter['observation'],
                    observation: iter['observation'],
                    variable: iter['variable'],
                    question: iter['question'],
                    impression_init: impression,
                    impression,
                    isLast,
                    isToBeQuestioned: iter['variable'] && Object.keys(iter['variable']).length > 0
                });
                if (iter['finding'] != 'manual')
                    newSelectedPathologies.push(iter['finding'])
            }
        });

        const updatedReportContents = newReportContents.map((content, index) => ({
            ...content,
            isLast: index === newReportContents.length - 1
        }));
        dispatch(resetContent(updatedReportContents));
        dispatch(resetSelectedPathology(newSelectedPathologies));

        setIsNonEditable(true);
        setNonEditableData(prevData => {
            if (prevData.length > 4) {
                return prevData + '\n' + textAreaDictate;
            }
            return textAreaDictate;
        });
        dispatch(setTextArea({ action: 'reset', text: '' }));
        dispatch(whisperStartListening())
        dispatch(augnitoStopListening())
    };

    return (
        <div>
            {/* <div className="h-[6vh] border-bt-2 flex justify-between items-center pr-4 py-4 bg-blue-50">
                <div className='w-full ml-2 font-bold'>
                    <CustomDropdown className="" />
                    Pathology
                </div>
                <div>
                    <Augnito sendMessageStream={onSendMessageStream}></Augnito>
                </div>
            </div> */}
            <div className={`h-[29vh] bg-white overflow-hidden flex flex-col rounded-lg justify-between p-2  relative w-full `}>
                {isLoading && (
                    <div className="flex items-center justify-center absolute inset-0 flex items-center justify-center z-10">
                        <div className="animate-spin rounded-full h-12 w-12 border-t-2  border-blue-500"></div>
                    </div>
                )}
                <div id="textareaContainer" className={`flex-1 overflow-auto ${isLoading ? 'bg-opacity-50 blur-sm' : ''}`}>
                    <div
                        id="nonEditableLines"
                        contentEditable={false}
                        className="whitespace-pre-wrap text-gray-400"
                        dangerouslySetInnerHTML={{ __html: isNonEditable ? nonEditableData.replace(/\n/g, '<br>') : '' }}
                    />
        
                    <textarea
                        id="editableLines"
                        value={textAreaDictate}
                        className={`h-full outline-none resize-none w-full ${isLoading ? 'bg-opacity-50 blur-sm' : ''} ${augnitoValue?'blink-cursor':null}`}
                        onChange={(e) => dispatch(setTextArea({ action: 'reset', text: e.target.value }))}
                        onFocus={() => dispatch(setCursorPosition('textArea'))}
                    />
                </div>
                <button
                    className={`absolute bottom-2 right-2 rounded-lg focus:outline-none ${textAreaDictate.length > 5 ? 'scale-100' : 'scale-75'}${isLoading ? 'bg-opacity-50 blur-sm' : ''}`}
                    style={{ float: 'right' }}
                    onClick={() => textAreaDictate.length > 5 && fetchReportContent(textAreaDictate)}
                    disabled={textAreaDictate.length <= 5}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -2 20 20" fill="currentColor" className="ml-2 h-6 w-6 rotate-90 transform">
                        <path fill="black" d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"></path>
                    </svg>
                </button>
            </div>
        </div>
    );
}

export default Dictate;
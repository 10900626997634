import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const loadFromSessionStorage = createAsyncThunk(
  'reportBuilder/loadFromSessionStorage',
  async () => {
    const patientData = JSON.parse(sessionStorage.getItem('patientData')) || {};
    const formData = JSON.parse(sessionStorage.getItem('formdatademo')) || null;
    return { patientData, formData };
  }
);


export const setJsonData = createAsyncThunk(
  'reportBuilder/setJsonData',
  async (jsonData) => {
    return jsonData;
  }
);

export const setFormData = createAsyncThunk(
  'reportBuilder/setFormData',
  async (formData) => {
    const formDataObj = {};
    for (let [key, value] of formData.entries()) {
      formDataObj[key] = value;
    }
    return formDataObj;
  }
);

export const setPathologyData = createAsyncThunk(
  'reportBuilder/setPathologyData',
  async (pathologyData) => {
    return pathologyData;
  }
);

export const setHtmlString = createAsyncThunk(
  'reportBuilder/setHtmlString',
  async (htmlString) => {
    return htmlString;
  }
);
export const setPathologyFromColIndex = createAsyncThunk(
  'reportBuilder/setPathologyFromColIndex',
  async (isFromColIndex) => {
    return isFromColIndex;
  }
);
export const setEmergencyCase = createAsyncThunk(
  'reportBuilder/setEmergencyCase',
  async (isFromColIndex) => {
    return isFromColIndex;
  }
);
export const setSurgicalCase = createAsyncThunk(
  'reportBuilder/setSurgicalCase',
  async (isFromColIndex) => {
    return isFromColIndex;
  }
);

export const setReasonData = createAsyncThunk(
  "OsteroCheck/setReasonData",
  async (reasonData, { rejectWithValue }) => {
    try {
      return reasonData;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const setfiltermodalities = createAsyncThunk(
  'reportBuilder/setfiltermodalities',
  async (filterModalities) => {
    return filterModalities;
  }
);

export const setfilterStatus = createAsyncThunk(
  'reportBuilder/setfilterStatus',
  async (filterStatus) => {
    return filterStatus;
  }
);
export const setfilterPathology = createAsyncThunk(
  'reportBuilder/setfilterPathology',
  async (filterPathology) => {
    return filterPathology;
  }
);
export const setfilterCase = createAsyncThunk(
  'reportBuilder/setfilterCase',
  async (filterCase) => {
    return filterCase;
  }
);

const reportBuilderSlice = createSlice({
  name: 'reportBuilder',
  initialState: {
    formData: null,
    pathologyData: [],
    jsonData: {},
    htmlString: '',
    loading: false,
    error: null,
    pathologyFromColIndex: false,
    emergencycase:false,
    surgicalcase:false,
    reasonData: null,
    filterModalities:[],
    filterStatus:[],
    filterPathology:[],
    filterCase:[]
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setFormData.pending, (state) => {
        state.loading = true;
      })
      .addCase(setFormData.fulfilled, (state, action) => {
        state.formData = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(setFormData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(setPathologyData.fulfilled, (state, action) => {
        state.pathologyData = action.payload;
      })
      .addCase(setHtmlString.fulfilled, (state, action) => {
        state.htmlString = action.payload;
      })
      .addCase(setJsonData.pending, (state) => {
        state.loading = true;
      })
      .addCase(setJsonData.fulfilled, (state, action) => {
        state.jsonData = action.payload;
        state.loading = false;
        state.error = null;

        sessionStorage.setItem('patientData', JSON.stringify(action.payload));
      })
      .addCase(setJsonData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(setPathologyFromColIndex.fulfilled, (state, action) => {
        state.pathologyFromColIndex = action.payload;
      })
      .addCase(setEmergencyCase.fulfilled, (state, action) => {
        state.emergencycase = action.payload;
      })
      .addCase(setSurgicalCase.fulfilled, (state, action) => {
        state.surgicalcase = action.payload;
      })
      .addCase(setReasonData.fulfilled, (state, action) => {
        state.reasonData = action.payload;
      })
      .addCase(setfiltermodalities.fulfilled, (state, action) => {
        state.filterModalities = action.payload;
      })
      .addCase(setfilterStatus.fulfilled, (state, action) => {
        state.filterStatus = action.payload;
      })
      .addCase(setfilterPathology.fulfilled, (state, action) => {
        state.filterPathology = action.payload;
      })
      .addCase(setfilterCase.fulfilled, (state, action) => {
        state.filterCase = action.payload;
      })
      .addCase(loadFromSessionStorage.fulfilled, (state, action) => {
        state.jsonData = action.payload.patientData;
        state.formData = action.payload.formData;
      });
  },
});

export default reportBuilderSlice.reducer;
import React, { useEffect, useState } from 'react';
import Dictate from '../components/report-builder/Dictate';
import Questions from '../components/report-builder/Question';
import Editor from '../components/report-builder/Editor';
import Dicom from '../components/report-builder/Dicom'
import CustomDropdown from '../components/report-builder/OptionDropDown';
import MessageInput from '../components/report-builder/MessageInput';
import { useSelector, useDispatch } from 'react-redux';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';

function getQueryParams() {
  const searchParams = new URLSearchParams(window.location.search);
  const accessToken = searchParams.get('accessToken');
  const idToken = searchParams.get('idToken');

  return { accessToken, idToken };
}

function MainChatContainer() {
  const { accessToken, idToken } = getQueryParams();
  const user = useSelector((state) => state.user);
  const isChatQuestionActive = useSelector((state) => state.reportContents.isChatQuestionActive);
  const messages = useSelector((state) => state.chatMessages.messages);
  const isChatBotActive = useSelector((state) => state.reportContents.isChatBotActive);
  const augnitoValue = useSelector((state) => state.augnito.valueFromAugntio);
  const [link, setlink] = useState('');
  const handle = useFullScreenHandle();

  const dispatch = useDispatch();



  if (!isChatBotActive) {
    const modal = document.getElementById('my_modal_3');
    if (modal) {
      modal.showModal();
    }
  }

  

  return (
    <div className="App">
        <div className="h-screen overflow-hidden max-w- mx-auto flex flex-col">
        <div className="border rounded-md border-solid shadow-lg bg-white ">
        <Navbar />
      </div>
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
          <div className="flex-grow flex">
            <Questions />
            <div className="flex-grow flex gap-[1em]">
             <Dicom/>
              <div
                className={`w-[50%]  ${!isChatBotActive && isChatQuestionActive ? 'blur-sm' : ''
                  }`}
              >
                <Editor />
              </div>
            </div>
          </div>
         </div>
        </div>
    </div>
  );
}

export default MainChatContainer;

import React, { useEffect, useState } from "react";
import FileUploadForm from "../components/DragDrop";
import ProgressBar from "../components/ProgressBar";
import Navbar from "../components/Navbar";
import PatientData from "../components/PatientData";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const UploadScan = () => {
  const navigate = useNavigate();
  const [loadingWidth, setLoadingWidth] = useState(0);
  const report = useSelector((state) => state.OsteroCheck.report);
  const patientData = useSelector(
    (state) => state.OsteroCheck.patientData
  );
  console.log("loading data", patientData.data.mod_study);

  useEffect(() => {
    if (report.loading) {
      setTimeout(() => {
        if (loadingWidth < 96) {
          setLoadingWidth(loadingWidth + 4);
        }
      }, 500);
    }
  }, [report.loading]);

  useEffect(() => {
    if (report.loading) {
      setTimeout(() => {
        if (loadingWidth < 96) {
          setLoadingWidth(loadingWidth + 4);
        }
      }, 500);
    }
  }, [loadingWidth]);

  return (
    <div className="flex justify-center h-screen w-screen py-4 px-8 sm:py-2 sm:px-2">
      <div className="h-full w-full">
        <div className="h-1/12">
          <Navbar />
        </div>

        <div className=" bg-white pt-4  grid grid-cols-4 grid-rows-3  gap-x-6 h-11/12 sm:flex sm:flex-col">
          <PatientData/>

          <div
            className="col-span-3  row-span-3 shadow-md rounded h-full
     rounded-2xl  p-8 bg-clean-white text-center
   border box-border  shadow-[4px_4px_4px_rgba(0,0,0,0.2)] border-gray-300 overflow-y-auto">
            {report.loading && (
              <button
                type="button"
                className="bg-green-100 p-1 border border-green-400 text-green-700 rounded "
                disabled>
                <div
                  className="inline-block mr-2 h-5 w-5 animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                  role="status">
                  <span className="!absolute !-m-px  !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                    Loading...
                  </span>
                </div>
                <span>
                  {patientData.data.mod_study === "ChestR"
                    ? "Analysing XRAY ..."
                    : patientData.data.mod_study === "SXR"
                    ? "Analysing XRAY..."
                    : patientData.data.mod_study === "MRI spine"
                    ? "Analysing MRI..."
                    : patientData.data.mod_study === "CT brain"
                    ? "Analysing CT Brain Scan ..."
                    : patientData.data.mod_study === "KXR"
                    ? "Analysing XRAY..."
                    : "Analysing Scan ..."}
                </span>
              </button>
            )}
            <div className="h-1/5 flex flex-col gap-4">
              <div className="flex text-dark-blue-60 gap-2  items-center">
                <img
                  className=" max-w-full overflow-hidden max-h-full"
                  alt=""
                  src="/img/back.svg"
                />
                <div
                  className="font-semibold inline-block"
                  onClick={() => navigate("/patient-info")}>
                  Back
                </div>
              </div>
              <ProgressBar
                patient_info={{ isDone: true, loading: 100 }}
                upload_scan={{ isDone: false, loading: loadingWidth }}
                report={{ isDone: false }}
              />

              <p className="font-bold mt-5 text-left text-lg">Upload Scans</p>
            </div>

            <div className="h-4/5 pt-4 flex flex-col justify-center items-center">
              {/* <FileUploadForm /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadScan;
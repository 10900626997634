import React from 'react';

const MeasurementTable = ({ data }) => {
  // Add checks for data existence
  if (!data || !data[0]?.measurements?.length) {
    return ;
  }

  const measurements = data[0].measurements;
  
  // Check if measurements have values
  if (!measurements[0]?.values?.length) {
    return <div>No measurement values available</div>;
  }

  // Get column headers dynamically from the data structure
  const headers = Object.keys(measurements[0].values[0]); // ['level', 'value']
  const levels = measurements[0].values.map(v => v.level);
  
  return (
    <div className="overflow-x-auto">
      <table className="min-w-[700px] w-auto max-w-[500px] border-collapse border border-gray-300 mt-2">
        <thead>
          <tr className='bg-gray-100'>
            {/* First header is the measurement type column */}
            <th className="border border-gray-300 p-2 text-sm font-semibold text-left">
             Measurements
            </th>
            {levels.map((level, index) => (
              <th key={index} className="border border-gray-300 p-2 text-sm font-semibold text-left">
                {level}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {measurements.map((measurement, index) => (
            <tr key={index}>
              <td className="border border-gray-300 p-2 text-sm text-left">
                {measurement.measurement_type}
              </td>
              {measurement.values.map((val, vIndex) => (
                <td key={vIndex} className="border border-gray-300 p-2 text-sm text-left">
                  {val.value} mm
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MeasurementTable;